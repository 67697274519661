<template>
    <div>
        <el-form ref="form" :model="terminalInfo" label-width="140px">
            <el-form-item label="sn号">
                <el-select v-model="terminalInfo.provinceCode" placeholder="请选择">
                    <el-option label="M70" value=""></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="机具型号">
                <el-select v-model="terminalInfo.provinceCode" placeholder="请选择">
                    <el-option label="M70" value=""></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="代理/机构编号">
                <el-select v-model="terminalInfo.provinceCode" placeholder="请选择">
                    <el-option label="M70" value=""></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="代理/机构名称">
                
            </el-form-item>
            <el-form-item label="机具政策">
                <el-select v-model="terminalInfo.provinceCode" placeholder="请选择">
                    <el-option label="M70" value=""></el-option>
                </el-select>
                <div>
                    
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    data(){
        return{
            terminalInfo:''
        }
    }
}
</script>